import React, { useEffect, useRef, useState } from "react";
import SocialMediaComponent from "../components/SocialMediaComponent";
import { colorsArkana } from "../constants/colorsArkana";
import logo from "../assets/logo/logo.png";
import { IProjectDB } from "../types/projects";
import useHttp from "../hooks/useHttp";
import seperador1 from "../assets/images/home/Separador1.png";
import texturaBlanca from "../assets/images/home/textura-blanca.jpg";
import hombrefondo from "../assets/images/home/hombrefondo.jpg";
import libro from "../assets/images/home/libro.jpg";
import tag from "../assets/images/home/tag.png";
import web from "../assets/images/home/web.jpg";
import celular from "../assets/images/home/celular.jpg";
import fondoasesoria from "../assets/images/home/fondoasesoria.jpg";
import milton from "../assets/images/home/milton.png";
import notas from "../assets/images/home/notas.jpg";
import instagramlike from "../assets/images/home/instagram-like-3507.png";
import redmundial from "../assets/images/home/red-mundial.png";
import desing from "../assets/images/home/design-process.png";
import puntos from "../assets/images/home/puntosblancos.png";
import { Button, Form, Input } from "antd";
import Swal from "sweetalert2";

const colors = ["#0088FE", "#00C49F", "#FFBB28"];
const delay = 1700;

const HomePage = () => {
  const [projects, setProjects] = useState<IProjectDB[]>([]);

  const refVideo = useRef<HTMLVideoElement>(null);
  const timeoutRef = useRef<any>(null);

  const { get, post } = useHttp();
  const [form] = Form.useForm();

  const [index, setIndex] = React.useState(0);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    if (timeoutRef !== null) {
      resetTimeout();
      timeoutRef.current = setTimeout(
        () =>
          setIndex((prevIndex) => {
            // console.log(prevIndex);
            // console.log(colors.length * 2 - 1);
            console.log(prevIndex);

            return prevIndex === 10 ? 0 : prevIndex + 1;
          }),
        delay
      );
    }

    return () => {
      resetTimeout();
    };
  }, [index]);

  useEffect(() => {
    handleGetProjects();

    if (refVideo.current) {
      refVideo.current.play();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetProjects = async () => {
    const projectsDB: { data: IProjectDB[] } = await get(
      "proyectos?populate[0]=portada&populate[1]=galeria"
    );

    setProjects(projectsDB.data);
  };

  return (
    <div
      style={{
        backgroundColor: "#06050A",
        // overflowX: "hidden",
      }}
    >
      <section
        style={{
          position: "relative",
          minHeight: "100vh",
          backgroundColor: "#06050A",
          color: "white",
        }}
      >
        <div
          style={{
            backgroundColor: "black",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <video
            ref={refVideo}
            // autoPlay={true}
            muted
            playsInline={true}
            loop
            className="fillWidth"
          >
            <source
              src="https://res.cloudinary.com/dxgccfu4a/video/upload/v1695668426/fuego_460fd75cd6.mp4"
              type="video/mp4"
            />
          </video>

          <div
            style={{
              // zIndex: 100,
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img className="home-logo" src={logo} alt="logo" style={{}} />

            <p
              style={{
                fontSize: "30px",
              }}
              className="text-center"
            >
              <span
                style={{
                  color: "white",
                  opacity: 1,
                }}
              >
                EL SECRETO DE UN
              </span>{" "}
              <span
                style={{
                  color: colorsArkana.secondary,
                }}
              >
                GRAN DISEÑO
              </span>{" "}
            </p>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
          }}
        >
          <SocialMediaComponent />
        </div>
      </section>

      <section
        style={{
          position: "relative",
          minHeight: "100vh",
          backgroundColor: "#06050A",
          backgroundImage: `url(${texturaBlanca})`,
          backgroundSize: "cover",
          color: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          id="separador1"
          src={seperador1}
          alt="separador1"
          style={{
            width: "100%",
            position: "absolute",

            left: "0",
            zIndex: 0,
          }}
        />

        <div
          className="row"
          style={{
            // minHeight: "50vh",
            maxWidth: "90vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="col-md-12 mt-5 about" style={{}}>
            <div
              id="aboutTitle"
              // className="mr-5"
              style={
                {
                  // fontSize: "3rem",
                }
              }
            >
              Sobre Nosotros.
            </div>

            <div
              id="about"
              style={{
                // width: "49%",
                // fontSize: "1.2rem",
                // padding: "0 2rem",
                // borderLeft: "",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Arkana Design Studio es un estudio de diseño gráfico que se
              especializa en crear soluciones visuales impactantes. Nuestro
              enfoque se basa en la creatividad, la energía y la seguridad,
              buscando siempre transmitir la personalidad única de cada marca a
              través del diseño. Nuestro objetivo es ser líderes en la
              industria, brindando servicios intuitivos y efectivos que ayuden a
              las marcas a destacarse y alcanzar el éxito. Con un equipo
              talentoso y una pasión por la excelencia, estamos comprometidos en
              ofrecer resultados tangibles y medibles para nuestros clientes.
              ¡Confía en Arkana Design Studio para potenciar la potencia visual
              de tu marca y llevarla al siguiente nivel!
            </div>
          </div>
          <div
            className="mt-5"
            style={{
              cursor: "pointer",
              border: "2px solid black",
              padding: " 0.7rem 3rem",
              marginLeft: "4.7m",
              fontSize: "1.7rem",
            }}
          >
            Saber más
          </div>
        </div>
      </section>

      <section
        className="container-fluid sectionServices "
        style={{
          minHeight: "100vh",
          backgroundColor: "black",
          backgroundImage: `url(${hombrefondo})`,
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="row mt-5"
          style={{
            maxWidth: "80wh",
          }}
        >
          <div className="col-md-3  col-6">
            <div
              className="mb-5"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
              }}
            >
              <img src={desing} width="40%" alt="libro" />
              Dinseño de marca
            </div>
            <div
              className="cardSectionServices"
              style={{
                // height: "65vh",
                // width: "100%",
                // borderRadius: "30px",
                backgroundImage: `url(${notas})`,
                backgroundSize: "cover",
              }}
            />
          </div>

          <div className="col-md-3 col-6">
            <div
              className="cardSectionServices"
              style={{
                // height: "65vh",
                // width: "100%",
                // borderRadius: "30px",
                backgroundImage: `url(${libro})`,
                backgroundSize: "cover",
              }}
            />
            <div
              className="mt-5 mb-5"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
              }}
            >
              <img src={tag} width="40%" alt="libro" />
              Branding
            </div>
          </div>

          <div className="col-md-3 col-6">
            <div
              className="mb-5 mt-5"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
              }}
            >
              <img src={redmundial} width="40%" alt="libro" />
              Diseño y programación web
            </div>
            <div
              className="cardSectionServices"
              style={{
                // height: "65vh",
                // width: "100%",
                // borderRadius: "30px",
                backgroundImage: `url(${web})`,
                backgroundSize: "cover",
              }}
            />
          </div>

          <div className="col-md-3 col-6">
            <div
              className="cardSectionServices"
              style={{
                // height: "65vh",
                // width: "100%",
                // borderRadius: "30px",
                backgroundImage: `url(${celular})`,
                backgroundSize: "cover",
              }}
            />
            <div
              className="mt-5"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
              }}
            >
              <img src={instagramlike} width="40%" alt="libro" />
              Redes sociales
            </div>
          </div>
        </div>
      </section>

      <section
        className="container-fluid"
        style={{
          position: "relative",
          minHeight: "100vh",
          backgroundColor: "black",
        }}
      >
        <div className="row">
          <div
            style={{
              minHeight: "33vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
            className="col-md-12"
          >
            <h2 className="text-center">
              Diseñamos marcas inspiradoras y experiencias en línea para
              clientes con visión para el futuro.
            </h2>
          </div>

          <div className="col-md-12">
            <div className="logos">
              <div className="logos-slide">
                {[
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                ].map((project, index) => (
                  <img key={index} src={project.portada?.url} alt="project" />
                ))}
              </div>

              {/* <div class="logos-slide">
                <img src="./logos/3m.svg" />
                <img src="./logos/barstool-store.svg" />
                <img src="./logos/budweiser.svg" />
                <img src="./logos/buzzfeed.svg" />
                <img src="./logos/forbes.svg" />
                <img src="./logos/macys.svg" />
                <img src="./logos/menshealth.svg" />
                <img src="./logos/mrbeast.svg" />
              </div> */}
            </div>
            {/* <div className="slideshow">
              <div
                className="slideshowSlider"
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
              >
                {[
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                  ...projects,
                ].map((backgroundColor, index) => (
                  <div className="slide" key={index}>
                    <img
                      src={backgroundColor.portada?.url}
                      alt="project"
                      style={{
                        width: "100%",
                        height: "100%",
                        // objectFit: "cover",
                        // borderRadius: "30px",
                      }}
                    />
                  </div>
                ))}
              </div> */}

            {/* <div className="slideshowDots">
                {colors.map((_, idx) => (
                  <div
                    key={idx}
                    className={`slideshowDot${index === idx ? " active" : ""}`}
                    onClick={() => {
                      setIndex(idx);
                    }}
                  ></div>
                ))}
              </div> */}
            {/* </div> */}

            {/* <div className="row">
              {projects.map((project, index) => (
                <div
                  className="col-md-12 mb-5"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    className={`img-projects${index % 2}`}
                    src={project.portada?.url}
                    alt="project"
                    style={{
                      width: "55%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "30px",
                    }}
                  />
                </div>
              ))}
            </div> */}
          </div>

          {/* <div
            style={{
              minHeight: "33vh",
            }}
            className="col-md-12"
          >
            <div className="row">
              <div className="col-md-2">
                <img src={puntos} alt="puntos" width="90%" />
                <img src={puntos} className="mt-2" alt="puntos" width="90%" />
              </div>
              <div className="col-md-8">
                <div className="row">
                  {projects.map((project) => (
                    <div
                      className="col-md-4"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={project.portada?.url}
                        alt="project"
                        style={{
                          width: "100%",
                          height: "50%",
                          objectFit: "cover",
                          borderRadius: "30px",
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-2">
                <img src={puntos} alt="puntos" width="90%" />
                <img src={puntos} className="mt-2" alt="puntos" width="90%" />
              </div>
            </div>
          </div> */}
          <div
            style={{
              minHeight: "33vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="col-md-12"
          >
            <div
              className="mt-5"
              style={{
                cursor: "pointer",
                border: "2px solid white",
                padding: " 0.7rem 3rem",
                marginLeft: "4.7m",
                fontSize: "1.7rem",
                width: "fit-content",
                color: "white",
              }}
            >
              Nuestros proyectos
            </div>
          </div>
        </div>
      </section>

      <section
        className="mb-5 about2"
        style={{
          position: "relative",
          minHeight: "65vh",
          marginLeft: "4vw",
          marginRight: "4vw",
          backgroundImage: `url(${fondoasesoria})`,
          backgroundSize: "cover",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          // flexDirection: "row",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            opacity: 0.8,

            background: "#06050A",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />

        <div
          style={{
            zIndex: 2,
            // width: "35%",
          }}
        >
          <p
            id="aboutTitle"
            style={
              {
                // fontSize: "30px",
                // width: "%",
              }
            }
            className="text-center"
          >
            <span
              style={{
                color: "white",
                opacity: 1,
              }}
            >
              Tu primera asesoria es{" "}
            </span>
            <span
              style={{
                color: colorsArkana.secondary,
              }}
            >
              GRATIS
            </span>
          </p>
        </div>

        <div
          className="aboutDiv"
          style={
            {
              // zIndex: 2,
              // width: "50%",
              // display: "flex",
              // justifyContent: "start",
              // alignItems: "start",
            }
          }
        >
          <p
            id="about2"
            style={{
              // fontSize: "1.3rem",
              color: "white",
              // textAlign: "center",
              // padding: "0 2rem",
            }}
          >
            Al contactar a Arkana Design Studio, tu primera asesoría de
            marketing va por nuestra cuenta. Ésta abarca una evaluación
            exhaustiva de tu marca, una revisión de tu presencia en línea, un
            análisis de la competencia y recomendaciones estratégicas
            personalizadas. Además, recibirás una consulta individual con un
            experto en diseño de marca y un plan de acción detallado para
            mejorar tu presencia en el mercado. Creemos en la importancia de
            demostrar el valor que Arkana Design Studio puede a su marca desde
            el principio. Al ofrecer una asesoría gratuita, no solo brindamos
            ayuda real y valiosa, sino que también mostramos nuestro compromiso
            genuino con el éxito de nuestros clientes.
          </p>
        </div>
      </section>

      <section
        className="container-fluid sectiionMilton"
        style={{
          minHeight: "100vh",
          backgroundColor: "#06050A",

          // backgroundImage: `url(${hombrefondo})`,
          // backgroundSize: "cover",

          // flexDirection: "column",
        }}
      >
        <img src={milton} alt="milton" className="sectionMilton_milton" />

        <p
          className="sectionMilton_text"
          style={
            {
              // paddingRight: "rem",
            }
          }
        >
          "Hay tres respuestas a una pieza de diseño: sí, no y !WOW! Wow es al
          que hay que aspirar". <br />
          <p
            style={{
              fontSize: "1.3rem",
              color: "white",
              textAlign: "end",
              padding: "0 2rem",
            }}
          >
            -Milton Glaser
          </p>
        </p>
      </section>

      <section
        className="about2 container-fluid"
        style={{
          position: "relative",
          minHeight: "50vh",
          // marginLeft: "4vw",
          backgroundColor: "black",
          // marginRight: "4vw",
          backgroundImage: `url(${fondoasesoria})`,
          backgroundSize: "cover",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          // flexDirection: "row",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            opacity: 0.4,
            background: "#06050A",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
        <div
          className="texto-form"
          style={{
            zIndex: 2,
            // width: "35%",
            color: "white",
          }}
        >
          <p
            // id="aboutTitle"
            style={{
              fontSize: "2.6rem",
              // width: "%",
            }}
            className="text-center"
          >
            Estás a un paso de mejorar tu futuro. <br /> ¡Contáctanos!
          </p>
        </div>

        <div
          className="aboutDiv mb-5"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "white",
            border: "4px solid grey",
            // borderTopLeftRadius: "20px",
            // borderTopRightRadius: "20px",
            // borderRadius: "20px",

            // zIndex: 2,
            // width: "50%",
            // display: "flex",
            // justifyContent: "start",
            // alignItems: "start",
          }}
        >
          <Form
            form={form}
            onFinish={async (e) => {
              try {
                await post("auth/local/register", {
                  username: e.nombre + " " + e.apellido + " " + e.telefono,
                  email: e.email,
                  password: "123456",
                });

                await Swal.fire({
                  icon: "success",
                  title: "¡Gracias por contactarnos!",
                  text: "En breve nos pondremos en contacto contigo",
                });

                form.resetFields();
              } catch (error) {
                await Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Algo salio mal, intentalo de nuevo",
                });
              }
            }}
            layout="vertical"
            className="row home-colform-form"
          >
            <div className="col-md-6">
              <Form.Item
                name="nombre"
                style={
                  {
                    // color: "white",
                  }
                }
                // label="Nombre"
                rules={[
                  {
                    required: true,
                    message: "El nombre es necesario",
                  },
                ]}
              >
                <Input
                  style={{
                    backgroundColor: "rgba(255,255,255,0.2)",
                    border: "4px solid grey",
                    borderRadius: "0px",
                    color: "white",
                  }}
                  placeholder="Nombre"
                />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="apellido"
                rules={[
                  {
                    required: true,
                    message: "El Apellido es necesario",
                  },
                ]}
              >
                <Input
                  style={{
                    backgroundColor: "rgba(255,255,255,0.2)",
                    border: "4px solid grey",
                    borderRadius: "0px",
                    color: "white",
                  }}
                  placeholder="Apellido"
                />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "El email es necesario",
                  },
                ]}
              >
                <Input
                  style={{
                    backgroundColor: "rgba(255,255,255,0.2)",
                    border: "4px solid grey",
                    borderRadius: "0px",
                    color: "white",
                  }}
                  placeholder="Email"
                />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="telefono"
                rules={[
                  {
                    required: true,
                    message: "El telefono es necesario",
                  },
                ]}
              >
                <Input
                  style={{
                    backgroundColor: "rgba(255,255,255,0.2)",
                    border: "4px solid grey",
                    borderRadius: "0px",
                    color: "white",
                  }}
                  placeholder="Telefono"
                />
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item
                name="mensaje"
                rules={[
                  {
                    required: true,
                    message: "El mensaje es necesario",
                  },
                ]}
              >
                <Input.TextArea
                  style={{
                    backgroundColor: "rgba(255,255,255,0.2)",
                    border: "4px solid grey",
                    borderRadius: "0px",
                    color: "white",
                  }}
                  placeholder="Mensaje"
                />
              </Form.Item>
            </div>

            <div className="col-md-12">
              <Button
                // style={{
                //   backgroundColor: colorsArkana.primary,
                //   color: "white",
                //   border: "none",
                //   borderRadius: "20px",
                // }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // cursor: "pointer",
                  // border: "0px solid white",
                  // padding: " 0.7rem 3rem",
                  // marginLeft: "4.7m",
                  // fontSize: "1.7rem",
                  backgroundColor: "transparent",
                  // width: "fit-content",
                  // color: "white",
                  border: "0px solid white",
                }}
                htmlType="submit"
                block
              >
                <div
                  // className="mt-1"
                  style={{
                    cursor: "pointer",
                    border: "4px solid gray",
                    padding: " 0.1rem 2rem",
                    marginLeft: "4.7m",
                    fontSize: "1.7rem",
                    backgroundColor: "transparent",
                    width: "fit-content",
                    color: "white",
                  }}
                >
                  Enviar
                </div>
              </Button>
            </div>
          </Form>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
