import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingArkanaComponent from "../components/LoadingArkanaComponent";
import { colorsArkana } from "../constants/colorsArkana";
import useHttp from "../hooks/useHttp";
import { IProjectDB } from "../types/projects";

const ProjectDetailPage = () => {
  const [project, setProject] = useState<IProjectDB>();

  const { get } = useHttp();
  const { id } = useParams();

  useEffect(() => {
    handleGetProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetProject = async () => {
    const projectDB: { data: IProjectDB } = await get(
      `proyectos/${id}?populate[0]=portada&populate[1]=galeria`
    );

    setProject(projectDB.data);
  };

  if (!project) {
    return (
      <div
        style={{
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: colorsArkana.primary,
        }}
      >
        <LoadingArkanaComponent />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          height: "90vh",
          backgroundImage: `url(${project?.portada.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: colorsArkana.primary,
          backgroundAttachment: "fixed",
          position: "relative",
          display: "flex",
        }}
      >
        <div
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "auto",
            left: 0,
            right: 0,
            top: "70%",
            zIndex: 3,
            backgroundColor: "white",
            color: "black",
            textAlign: "center",
            width: "90%",
            maxHeight: "80vh",
            overflowY: "auto",
            borderRadius: "10px",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
            padding: "35px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
          }}
          className="container-fluid"
        >
          <h1 className="text-center">{project.titulo}</h1>
          <br />
          <div
            style={{
              borderBottom: "1px solid black",
              width: "20%",
            }}
          />

          <p
            className="mt-5"
            style={{
              textAlign: "justify",
              fontSize: "1.2rem",
              wordWrap: "break-word",
            }}
          >
            <strong>Descripción:</strong> {project.descripcion}
          </p>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "auto",
          left: 0,
          right: 0,
        }}
        className="container"
      >
        <div className="row margin-top-devices">
          {project.galeria.map((image) => {
            return (
              <div className="col-12 col-md-12 col-lg-12 mb-5 img-hover-zoom">
                <img
                  src={image.url}
                  alt="imag"
                  className="img-hover-zoom img-fluid"
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ProjectDetailPage;
