import React, { useEffect } from "react";
import fbBK from "../assets/icons/media/FB-BK.png";
import fbBL from "../assets/icons/media/FB-BL.png";
import igBK from "../assets/icons/media/IG-BK.png";
import igBL from "../assets/icons/media/IG-BL.png";
import msnBk from "../assets/icons/media/MSN_BK.png";
import msnBL from "../assets/icons/media/MSN_BL.png";
import waBk from "../assets/icons/media/WA-BK.png";
import waBL from "../assets/icons/media/WA-BL.png";

interface IPropsSocialMediaComponent {
  isDark?: boolean;
}

const SocialMediaComponent = ({ isDark }: IPropsSocialMediaComponent) => {
  useEffect(() => {
    console.log("isDark", isDark);
  }, [isDark]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
      }}
    >
      <a
        href="https://www.facebook.com/arkanadesign"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={isDark ? fbBK : fbBL}
          alt="Facebook"
          style={{
            width: "2rem",
            height: "2rem",
            margin: "0.5rem",
          }}
        />
      </a>
      <a
        href="https://www.facebook.com/messages/t/100832286266183"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={isDark ? msnBk : msnBL}
          alt="Whatsapp"
          style={{
            width: "2rem",
            height: "2rem",
            margin: "0.5rem",
          }}
        />
      </a>
      <a
        href="https://www.instagram.com/arkanadesignmx/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={isDark ? igBK : igBL}
          alt="Instagram"
          style={{
            width: "2rem",
            height: "2rem",
            margin: "0.5rem",
          }}
        />
      </a>

      <a
        href="https://api.whatsapp.com/send?phone=3327495711"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={isDark ? waBk : waBL}
          alt="Whatsapp"
          style={{
            width: "2rem",
            height: "2rem",
            margin: "0.5rem",
          }}
        />
      </a>
    </div>
  );
};

export default SocialMediaComponent;
