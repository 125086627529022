import { useEffect, useState } from "react";
import logo from "../assets/logo/logo.png";
import { colorsArkana } from "../constants/colorsArkana";

const LoadingArkanaComponent = () => {
  const [opacity, setOpacity] = useState<{
    isVisible: boolean;
    opacity: number;
  }>({
    isVisible: false,
    opacity: 0,
  });

  useEffect(() => {
    let interVal = setInterval(() => {
      setOpacity((data) => {
        if (data.opacity > 1 && !data.isVisible) {
          return {
            isVisible: true,
            opacity: data.opacity,
          };
        }

        if (data.opacity < 0 && data.isVisible) {
          return {
            isVisible: false,
            opacity: data.opacity,
          };
        }

        console.log(data);

        return {
          isVisible: data.isVisible,
          opacity: !data.isVisible ? data.opacity + 0.1 : data.opacity - 0.1,
        };
      });
    }, 90);

    return () => {
      clearInterval(interVal);
    };
  }, []);

  return (
    <img
      src={logo}
      style={{
        opacity: opacity.opacity,
        backgroundColor: colorsArkana.primary,
      }}
      className="img-fluid"
      alt=""
    />
  );
};

export default LoadingArkanaComponent;
