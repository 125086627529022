import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GlobalProvider from "./context/GlobalContext";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import ProjectDetailPage from "./pages/ProjectDetailPage";
import ProjectsPage from "./pages/ProjectsPage";

const App = () => {
  return (
    <BrowserRouter>
      <GlobalProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/proyectos">
            <Route index element={<ProjectsPage />} />
            <Route path=":id" element={<ProjectDetailPage />} />
          </Route>

          <Route path="/contacto" element={<ContactPage />} />
        </Routes>
      </GlobalProvider>
    </BrowserRouter>
  );
};

export default App;
