import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../assets/logo/logo.png";
import LoadingArkanaComponent from "../components/LoadingArkanaComponent";
import { colorsArkana } from "../constants/colorsArkana";
import useHttp from "../hooks/useHttp";
import { IProjectDB } from "../types/projects";

const ProjectsPage = () => {
  const [projects, setProjects] = React.useState<IProjectDB[]>([]);

  const navigation = useNavigate();
  const { get } = useHttp();

  useEffect(() => {
    handleGetProjects();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetProjects = async () => {
    const projectsDB: { data: IProjectDB[] } = await get(
      "proyectos?populate[0]=portada&populate[1]=galeria"
    );

    setProjects(projectsDB.data);
  };

  return (
    <div>
      <div
        style={{
          height: "90vh",
          // backgroundImage:
          //    "url(https://arkanaestudio.s3.us-east-2.amazonaws.com/1096864_d6673dd49e.png?updated_at=2023-02-11T16:08:12.057Z)",
          backgroundColor: colorsArkana.primary,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          position: "relative",
          display: "flex",
        }}
      >
        <img
          src={logo}
          alt="logo"
          className="img-fluid mt-5"
          style={{
            zIndex: 2,
            position: "fixed",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
          }}
        />
        {/* div con posicion absoluta centrado en pedio con un z index mayor al padre */}
        <div
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "auto",
            left: 0,
            right: 0,
            top: "45%",
            zIndex: 3,
            backgroundColor: "white",
            color: "white",
            textAlign: "center",
            width: "90%",
            minHeight: "90vh",
            borderRadius: "10px",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
            padding: "35px",
          }}
          className="container-fluid"
        >
          {projects.length === 0 ? (
            <LoadingArkanaComponent />
          ) : (
            <div style={{}} className="row mt-5">
              {projects.map((project) => {
                return (
                  <div
                    style={{
                      height: "30vh",
                    }}
                    className="col-12 col-md-6 col-lg-4 mb-3 img-hover-zoom"
                  >
                    <div
                      onClick={() => {
                        navigation(`/proyectos/${project.id}`);
                      }}
                      className="img-hover-zoom"
                      style={{
                        height: "100%",
                        width: "100%",
                        backgroundImage: `url(${project.portada.url})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: colorsArkana.secondary,
                        borderRadius: "10px",
                        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
                        cursor: "pointer",
                        flexDirection: "column",
                      }}
                    >
                      <h2>{project.titulo}</h2>
                      <br />
                      {/* etiqueta p que se ajuste al tamaño del div para mostrar el texto*/}
                      <p>{project.resumen}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectsPage;
