import React from "react";

const ContactPage = () => {
  return (
    <section
      style={{
        height: "100vh",
        backgroundColor: "white",
      }}
    >
      <div className="mb-2">
        <h2
          style={{
            fontWeight: "bold",
          }}
          className="text-center p-5"
        >
          Ven, cuéntanos tu proyecto.
        </h2>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70%",
          backgroundImage: `url(https://static.wixstatic.com/media/078d305c1ad24c51a5cb407913265dcf.jpg/v1/crop/x_207,y_0,w_5476,h_3840/fill/w_980,h_686,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/078d305c1ad24c51a5cb407913265dcf.jpg)`,
          backgroundSize: "cover",
          position: "relative",
        }}
        className="container"
      >
        <div
          className="cardincontactpage"
          style={{
            position: "absolute",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
            padding: "32px",
          }}
        >
          <div className="row mb-2">
            <h2>Arkana Design Studio</h2>
          </div>
          <div
            style={{
              borderBottom: "1px solid black",
              width: "20%",
            }}
            className="row mb-5"
          ></div>
          <div className="row mb-5">
            <div className="col-12">
              {/* <p>
                500 Terry Francois Street <br />
                San Francisco, CA 94158
              </p> */}

              <br />
              <p>contacto@arkanadesignstudio.com</p>
              <br />
              {/* <p>
                Tel: 123-456-7890 <br />
                Fax: 123-456-7890
              </p> */}
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
