import { createContext } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo/logonav.png";
import { colorsArkana } from "../constants/colorsArkana";
import iconW from "../assets/icons/IconoW.png";
export const GlobalContext = createContext({});

const GlobalProvider = ({ children }: any) => {
  const location = useLocation();

  return (
    <GlobalContext.Provider value={{}}>
      <div>
        <nav
          style={{
            // backgroundColor: "black",
            //backgroungdColor gris muy opaco
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
          }}
          className="navbar navbar-expand-lg navbar-dark fixed-top"
        >
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse"
            style={{
              //center the
              justifyContent: "space-around",
            }}
            id="navbarNavAltMarkup"
          >
            <Link
              className="navbar-brand"
              style={{
                color: "white",
                opacity: 1,
                letterSpacing: "0em",
                display: "flex",
              }}
              to="/"
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "20%",
                }}
              />
            </Link>
            <div
              style={{
                width: "33%",
              }}
            ></div>
            <div
              style={{
                width: "33%",
                display: "flex",
                justifyContent: "end",
              }}
              className="navbar-nav"
            >
              <Link className="nav-itemcss nav-linkcss hoveritem" to="/">
                INICIO <span className="sr-only">(current)</span>
              </Link>
              <Link className="nav-itemcss nav-linkcss hoveritem" to="/">
                NOSOTROS
              </Link>
              {/* <Link
                className="nav-itemcss nav-linkcss hoveritem"
                to="/proyectos"
              >
                PROYECTOS
              </Link> */}
              <Link
                className="nav-itemcss nav-linkcss hoveritem"
                to="/contacto"
              >
                CONTACTO
              </Link>
            </div>
          </div>
        </nav>
      </div>

      {children}
      {!location.pathname.includes("proyectos") && (
        <div
          style={{
            backgroundColor: "#06050A",
          }}
        >
          <footer
            style={{
              backgroundColor: colorsArkana.primary,

              color: "white",
              height: "2.5rem",
              zIndex: 100,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderTopLeftRadius: "1rem",
              borderTopRightRadius: "1rem",
            }}
            className="container-fluid"
          >
            <div
              style={{
                width: "33%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Link className="hoveritem" to="/">
                @ 2023 Arkana Design Studio
              </Link>
            </div>
            <div
              style={{
                width: "33%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={iconW} alt="" className="footer-logo" />
            </div>
            <div
              style={{
                width: "33%",
              }}
            ></div>
          </footer>
        </div>
      )}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
